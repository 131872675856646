/* DIALOGS */

.dialog {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: all 0.25s ease;
}

.dialog:not(.visible) {
  visibility: hidden;
  opacity: 0;
}

.dialog .dialog-backdrop > * {
  transition: all 0.25s ease;
  transform: translateY(10vh);
}

.dialog.visible .dialog-backdrop > * {
  transform: translateY(0);
}

.dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.25);
  padding: 16px;
  height: 100vh;
}

#card-legal-notice {
  background: #fff;
  padding: 0 48px 48px 48px;
  box-shadow: 0 2px 4px rgba(57, 58, 151, 0.25);
  overflow: hidden;
  max-width: 600px;
  .title {
    margin-top: 48px;
    margin-bottom: 32px;
    font-family: Oswald, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 71px;
    text-align: center;
    color: #393a97;
  }
}

#card-legal-notice-img {
  margin-top: 48px;
  background-color: transparent;
  border: none;
}

#card-legal-notice .wrapper-title {
  flex-shrink: 0;
}

#card-legal-notice .title {
  text-align: left;
}

.card-legal-notice-scroller {
  overflow: auto;
}

#wrapper-dialog-cookies {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 16px;
  max-width: 555px;
  opacity: 1;
  transition: all 0.25s ease;
  #button-close-dialog-cookies {
    border: none;
  }
}

#wrapper-dialog-cookies:not(.visible) {
  visibility: hidden;
  opacity: 0;
  transform: translateX(100%);
}

#dialog-cookies {
  background: #fff;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(57, 58, 151, 0.25);
}

#dialog-cookies .subtitle {
  margin-top: 0;
}

#dialog-cookies button {
  margin: 0;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  margin-top: 24px;
  margin-bottom: 16px;
  color: #18192d;
}
